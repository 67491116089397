import { Card } from "../../../components/ui/card"
import { Input } from "../../../components/ui/input"
import { Label } from "../../../components/ui/label"
import { Sheet, SheetContent } from "../../../components/ui/sheet"
import { IMGURL } from "../../../constants/appConfig"
import { ChangeEvent, useEffect, useState } from "react"
import { fabric } from 'fabric';
import { useGetLogodesignsMutation } from "../../../redux/slice/admin/ALogoApiSlice"
import ReactDOM from 'react-dom/client';
import { MdDelete } from 'react-icons/md';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Dialog, DialogContent } from "../../../components/ui/dialog"

// export const SelectImage = ({ editor, setModalOpen, groupSelect, fixedTopLayerImg }: any) => {
//   const uniqueId = uuidv4();
//   const [getLogoDesigns, { data: logos }] = useGetLogodesignsMutation()
//   const [open, setOpen] = useState(true)
//   const handleClose = () => {
//     setOpen(false)
//     setModalOpen(false)
//   }

//   const handleImageUpload = (url: string) => {
//     setModalOpen(false);
//     if (!editor || !editor.canvas || !groupSelect) return;

//     // Check if url is a valid URL by checking if it starts with 'http' or 'https'
//     if (url.startsWith('http://') || url.startsWith('https://')) {
//       fetch(url, { mode: 'cors' })
//         .then(response => response.blob())
//         .then(blob => {
//           const reader = new FileReader();
//           reader.onload = () => {
//             fabric.Image.fromURL(reader.result as string, (img: fabric.Image) => {
//               img.set({
//                 crossOrigin: 'anonymous',
//                 angle:groupSelect?.angle||0,
//                 left: groupSelect.left,
//                 top: groupSelect.top,
//                 scaleX: (groupSelect.width! * (groupSelect.scaleX || 1)) / img.width!,
//                 scaleY: (groupSelect.height! * (groupSelect.scaleY || 1)) / img.height!,
//                 selectable: true,
//                 hasControls: true,
//                 hasBorders: true,
//               });
//               img.setControlsVisibility({
//                 tl: true,
//                 tr: true,
//                 bl: true,
//                 br: true,
//                 ml: false, // Middle left (horizontal scaling)
//                 mr: false, // Middle right (horizontal scaling)
//                 mt: false, // Middle top (vertical scaling)
//                 mb: false,
//               });
//               img.set({
//                 cornerSize: 14,
//                 cornerColor: 'blue',
//                 cornerStyle: 'circle',
//                 transparentCorners: false,
//               });
//               (img as any).id = 'groupSelectImage';
//               //editor.canvas.remove(groupSelect);
//               groupSelect.set({ visible: false })
//               editor.canvas.add(img);
//               if (fixedTopLayerImg) editor.canvas.sendToBack(img);
//               img.on('mousedown', () => {
//                 showDeleteIcon(img, editor, groupSelect);
//               });
//               (img as any).uuid = uniqueId;
//               (groupSelect as any).uuid = uniqueId;
//               editor.canvas.setActiveObject(img);
//               editor.canvas.renderAll();
//             }, { crossOrigin: 'anonymous' });
//           };
//           reader.readAsDataURL(blob);
//         })
//         .catch(error => {
//           console.error('Error fetching image:', error);
//         });
//     }
//   };

//   const handleLocalImageChange = (event: ChangeEvent<HTMLInputElement>) => {
//     const files = event.target.files;
//     setModalOpen(false);
//     if (files && editor && editor.canvas && groupSelect) {
//       const reader = new FileReader();
//       reader.onload = () => {
//         fabric.Image.fromURL(reader.result as string, (img) => {
//           if (img.width && img.height) {
//             const widthFix = (groupSelect.scaleX && groupSelect.width) ? groupSelect.width * groupSelect.scaleX : groupSelect.width!;
//             const heightFix = (groupSelect.scaleY && groupSelect.height) ? groupSelect.height * groupSelect.scaleY : groupSelect.height!;

//             img.set({
//               angle:groupSelect?.angle||0,
//               left: groupSelect.left,
//               top: groupSelect.top,
//               scaleX: widthFix / img.width,
//               scaleY: heightFix / img.height,
//               selectable: true,
//               hasControls: true,
//               hasBorders: true,
//             });

//             img.setControlsVisibility({
//               tl: true,
//               tr: true,
//               bl: true,
//               br: true,
//               ml: false, // Middle left (horizontal scaling)
//               mr: false, // Middle right (horizontal scaling)
//               mt: false, // Middle top (vertical scaling)
//               mb: false,
//             });
//             img.set({
//               cornerSize: 14,
//               cornerColor: 'blue',
//               cornerStyle: 'circle',
//               transparentCorners: false,
//             });
//             (img as any).id = 'groupSelectImage';
//             //(img as any).id = '';
//             //  editor.canvas.remove(groupSelect);
//             groupSelect.set({ visible: false })
//             editor.canvas.add(img);
//             if (fixedTopLayerImg) editor.canvas.sendToBack(img);
//             img.on('mousedown', () => {
//               showDeleteIcon(img, editor, groupSelect);
//             });
//             (img as any).uuid = uniqueId;
//             (groupSelect as any).uuid = uniqueId;
//             editor.canvas.setActiveObject(img);
//             editor.canvas.renderAll();
//           } else {
//             console.error("Image dimensions are not defined.");
//           }
//         });
//       };
//       reader.readAsDataURL(files[0]);
//     }
//   };


//   useEffect(() => { getLogoDesigns({}) }, [getLogoDesigns])

//   return <Sheet open={open} onOpenChange={() => {
//     if (!open) {
//       setOpen(true);
//     } else {
//       handleClose();
//     }
//   }}>
//     {/* <SheetTrigger asChild >
//         <Button className='headermenu w-[150px]' size="sm">Select From Gallery</Button>
//       </SheetTrigger> */}
//     <SheetContent side="left" className="overflow-y-scroll max-h-screen w-[350px] " >
//       <div>
//         <Label htmlFor="local-image" className='headermenu p-2 rounded-lg text-white'>Upload Your Image</Label>
//         <Input id="local-image" type="file" onChange={handleLocalImageChange} className='hidden' />
//       </div>
//       {logos?.result?.length > 0 && <p className="mb-3 mt-4 font-semibold text-sm">Select image from Down:</p>}
//       <div className='flex gap-3 flex-wrap my-3'>
//         {logos?.result?.length > 0 && logos?.result?.map((logo: any) => <Card key={logo.id} className="w-[80px]" onClick={() => { setOpen(false); handleImageUpload(`${IMGURL}${logo.designUrl}`) }}>
//           <img src={`${IMGURL}${logo.designUrl}`} alt="template" className="w-full h-[80px]" />
//         </Card>)}
//       </div>
//     </SheetContent>
//   </Sheet>
// }
export const SelectImage = ({ editor, setModalOpen, groupSelect, fixedTopLayerImg }: any) => {
  const uniqueId = uuidv4();
  const [getLogoDesigns, { data: logos }] = useGetLogodesignsMutation()
  const [open, setOpen] = useState(true)
  const handleClose = () => {
    setOpen(false)
    setModalOpen(false)
  }

  const handleImageUpload = (url: string) => {
    setModalOpen(false);
    if (!editor || !editor.canvas || !groupSelect) return;

    // Check if url is a valid URL by checking if it starts with 'http' or 'https'
    if (url.startsWith('http://') || url.startsWith('https://')) {
      fetch(url, { mode: 'cors' })
        .then(response => response.blob())
        .then(blob => {
          const reader = new FileReader();
          reader.onload = () => {
            fabric.Image.fromURL(reader.result as string, (img: fabric.Image) => {
              let scaleX = (groupSelect.width! * (groupSelect.scaleX || 1)) / img.width!;
              let scaleY = (groupSelect.height! * (groupSelect.scaleY || 1)) / img.height!;

              if (scaleX < scaleY) {
                scaleY = scaleX;
              } else {
                scaleX = scaleY;
              }
              
              img.set({
                crossOrigin: 'anonymous',
                angle: groupSelect?.angle || 0,
                left: groupSelect.left,
                top: groupSelect.top,
                // scaleX: (groupSelect.width! * (groupSelect.scaleX || 1)) / img.width!,
                // scaleY: (groupSelect.height! * (groupSelect.scaleY || 1)) / img.height!,
                scaleX,
                scaleY,
                selectable: true,
                hasControls: true,
                hasBorders: true,
              });
            
              img.setControlsVisibility({
                tl: true,
                tr: true,
                bl: true,
                br: true,
                ml: false, // Middle left (horizontal scaling)
                mr: false, // Middle right (horizontal scaling)
                mt: false, // Middle top (vertical scaling)
                mb: false,
              });
              img.set({
                cornerSize: 14,
                cornerColor: 'blue',
                cornerStyle: 'circle',
                transparentCorners: false,
              });
              (img as any).id = 'groupSelectImage';
              //editor.canvas.remove(groupSelect);
              groupSelect.set({ visible: false })
              editor.canvas.add(img);
              if (fixedTopLayerImg) editor.canvas.sendToBack(img);
              img.on('mousedown', () => {
                showDeleteIcon(img, editor, groupSelect);
              });
              (img as any).uuid = uniqueId;
              (groupSelect as any).uuid = uniqueId;
              editor.canvas.setActiveObject(img);
              editor.canvas.renderAll();
            }, { crossOrigin: 'anonymous' });
          };
          reader.readAsDataURL(blob);
        })
        .catch(error => {
          console.error('Error fetching image:', error);
        });
    }
  };

  const handleLocalImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    setModalOpen(false);
    if (files && editor && editor.canvas && groupSelect) {
      const reader = new FileReader();
      reader.onload = () => {
        fabric.Image.fromURL(reader.result as string, (img) => {
          if (img.width && img.height) {
            const widthFix = (groupSelect.scaleX && groupSelect.width) ? groupSelect.width * groupSelect.scaleX : groupSelect.width!;
            const heightFix = (groupSelect.scaleY && groupSelect.height) ? groupSelect.height * groupSelect.scaleY : groupSelect.height!;
            let scaleX = widthFix / img.width;
            let scaleY = heightFix / img.height;

            if (scaleX < scaleY) {
              scaleY = scaleX;
            } else {
              scaleX = scaleY;
            }

            img.set({
              angle: groupSelect?.angle || 0,
              left: groupSelect.left,
              top: groupSelect.top,
              // scaleX: widthFix / img.width,
              // scaleY: heightFix / img.height,
              scaleX: scaleX,
              scaleY: scaleY,
              selectable: true,
              hasControls: true,
              hasBorders: true,
            });

            img.setControlsVisibility({
              tl: true,
              tr: true,
              bl: true,
              br: true,
              ml: false, // Middle left (horizontal scaling)
              mr: false, // Middle right (horizontal scaling)
              mt: false, // Middle top (vertical scaling)
              mb: false,
            });
            img.set({
              cornerSize: 14,
              cornerColor: 'blue',
              cornerStyle: 'circle',
              transparentCorners: false,
            });
            (img as any).id = 'groupSelectImage';
            //(img as any).id = '';
            //  editor.canvas.remove(groupSelect);
            groupSelect.set({ visible: false })
            editor.canvas.add(img);
            if (fixedTopLayerImg) editor.canvas.sendToBack(img);
            img.on('mousedown', () => {
              showDeleteIcon(img, editor, groupSelect);
            });
            (img as any).uuid = uniqueId;
            (groupSelect as any).uuid = uniqueId;
            editor.canvas.setActiveObject(img);
            editor.canvas.renderAll();
          } else {
            console.error("Image dimensions are not defined.");
          }
        });
      };
      reader.readAsDataURL(files[0]);
    }
  };


  useEffect(() => { getLogoDesigns({}) }, [getLogoDesigns])

  return <Dialog open={open} onOpenChange={() => {
    if (!open) {
      setOpen(true);
    } else {
      handleClose();
    }
  }}>
    {/* <SheetTrigger asChild >
        <Button className='headermenu w-[150px]' size="sm">Select From Gallery</Button>
      </SheetTrigger> */}
    <DialogContent className="overflow-y-auto max-h-screen sm:w-[450px] " >
      <div>
        <Label htmlFor="local-image" className='headermenu p-2 rounded-lg text-white'>Upload Your Image</Label>
        <Input id="local-image" type="file" onChange={handleLocalImageChange} className='hidden' />
      </div>
      {logos?.result?.length > 0 && <p className="my-2 font-semibold text-sm">Select image from Down:</p>}
      <div className='flex gap-3 flex-wrap my-1'>
        {logos?.result?.length > 0 && logos?.result?.map((logo: any) => <Card key={logo.id} className="w-[80px]" onClick={() => { setOpen(false); handleImageUpload(`${IMGURL}${logo.designUrl}`) }}>
          <img src={`${IMGURL}${logo.designUrl}`} alt="template" className="w-full h-[80px] object-cover" />
        </Card>)}
      </div>
    </DialogContent>
  </Dialog>
}

// export const SelectImagePrintable = ({ editor, setSheetOpen, printableAreas }: any) => {

//   const [getLogoDesigns, { data: logos }] = useGetLogodesignsMutation()
//   const [open, setOpen] = useState(true)
//   const handleClose = () => {
//     setOpen(false)
//     setSheetOpen(false)
//   }

//   const handleImageUpload = (url: string) => {

//     setSheetOpen(false);
//     if (!editor || !editor.canvas) return;

//     // Check if url is a valid URL by checking if it starts with 'http' or 'https'
//     if (url.startsWith('http://') || url.startsWith('https://')) {
//       fetch(url, { mode: 'cors' })
//         .then(response => response.blob())
//         .then(blob => {
//           const reader = new FileReader();
//           reader.onload = () => {
//             fabric.Image.fromURL(reader.result as string, (img: fabric.Image) => {
//               const area = printableAreas.length > 0 && printableAreas[0].getBoundingRect();
//               img.scaleToWidth(100);
//               img.scaleToHeight(100);
//               img.set({
//                 left: area?.left + 5 || 0,
//                 top: area?.top + 5 || 0,
//               });
//               img.setControlsVisibility({
//                 tl: true,
//                 tr: true,
//                 bl: true,
//                 br: true,
//                 ml: false, // Middle left (horizontal scaling)
//                 mr: false, // Middle right (horizontal scaling)
//                 mt: false, // Middle top (vertical scaling)
//                 mb: false,
//               });
//               img.set({
//                 cornerSize: 14,
//                 cornerColor: 'blue',
//                 cornerStyle: 'circle',
//                 transparentCorners: false,
//               });
//               editor.canvas.add(img);
//               // editor.canvas.sendToBack(img);
//               (img as any).id = 'customImage';
//               img.on('mousedown', () => {
//                 showDelete(img, editor);
//               });
//               editor.canvas.renderAll();
//             });
//           };
//           reader.readAsDataURL(blob);
//         })
//         .catch(error => {
//           console.error('Error fetching image:', error);
//         });
//     }
//   };

//   const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
//     const files = e.target.files;
//     setSheetOpen(false);
//     if (files && files.length > 0 /* && printableArea */ && editor) {
//       Array.from(files).forEach(file => {
//         const reader = new FileReader();
//         reader.onload = (event) => {
//           fabric.Image.fromURL(event.target?.result as string, (oImg) => {
//             const area = printableAreas.length > 0 && printableAreas[0]?.getBoundingRect();

//             oImg.scaleToWidth(100);
//             oImg.scaleToHeight(100);
//             oImg.set({
//               left: area?.left + 5 || 0,
//               top: area?.top + 5 || 0,
//             });
//             oImg.setControlsVisibility({
//               tl: true,
//               tr: true,
//               bl: true,
//               br: true,
//               ml: false, // Middle left (horizontal scaling)
//               mr: false, // Middle right (horizontal scaling)
//               mt: false, // Middle top (vertical scaling)
//               mb: false,
//             });
//             oImg.set({
//               cornerSize: 14,
//               cornerColor: 'blue',
//               cornerStyle: 'circle',
//               transparentCorners: false,
//             });
//             editor.canvas.add(oImg);
//             //editor.canvas.sendToBack(oImg);
//             (oImg as any).id = 'customImage';
//             oImg.on('mousedown', () => {
//               showDelete(oImg, editor);
//             });
//             editor.canvas.renderAll()
//           });
//         };
//         reader.readAsDataURL(file);
//       });
//     }
//   };

//   useEffect(() => { getLogoDesigns({}) }, [])

//   return <Sheet open={open} onOpenChange={() => {
//     if (!open) {
//       setOpen(true);
//     } else {
//       handleClose();
//     }
//   }}>
//     {/* <SheetTrigger asChild >
//         <Button className='headermenu w-[150px]' size="sm">Select From Gallery</Button>
//       </SheetTrigger> */}
//     <SheetContent side="left" className="overflow-y-scroll max-h-screen w-[350px] " >
//       <div>
//         <Label htmlFor="local-image" className='headermenu p-2 rounded-lg text-white'>Upload Your Image</Label>
//         <Input id="local-image" type="file" onChange={handleImageChange} className='hidden' />
//       </div>
//       {logos?.result?.length > 0 && <p className="mb-3 mt-4 font-semibold text-sm">Select image from Down:</p>}
//       <div className='flex gap-3 flex-wrap my-3'>
//         {logos?.result?.length > 0 && logos?.result?.map((logo: any) => <Card key={logo.id} className="w-[80px]" onClick={() => { setOpen(false); handleImageUpload(`${IMGURL}${logo.designUrl}`) }}>
//           <img src={`${IMGURL}${logo.designUrl}`} alt="template" className="w-full h-[80px]" />
//         </Card>)}
//       </div>
//     </SheetContent>
//   </Sheet>
// }
export const SelectImagePrintable = ({ editor, setSheetOpen, printableAreas }: any) => {

  const [getLogoDesigns, { data: logos }] = useGetLogodesignsMutation()
  const [open, setOpen] = useState(true)
  const handleClose = () => {
    setOpen(false)
    setSheetOpen(false)
  }

  const handleImageUpload = (url: string) => {

    setSheetOpen(false);
    if (!editor || !editor.canvas) return;

    // Check if url is a valid URL by checking if it starts with 'http' or 'https'
    if (url.startsWith('http://') || url.startsWith('https://')) {
      fetch(url, { mode: 'cors' })
        .then(response => response.blob())
        .then(blob => {
          const reader = new FileReader();
          reader.onload = () => {
            fabric.Image.fromURL(reader.result as string, (img: fabric.Image) => {
              const area = printableAreas.length > 0 && printableAreas[0].getBoundingRect();
              img.scaleToWidth(100);
              img.scaleToHeight(100);
              img.set({
                left: area?.left + 5 || 0,
                top: area?.top + 5 || 0,
              });
              img.setControlsVisibility({
                tl: true,
                tr: true,
                bl: true,
                br: true,
                ml: false, // Middle left (horizontal scaling)
                mr: false, // Middle right (horizontal scaling)
                mt: false, // Middle top (vertical scaling)
                mb: false,
              });
              img.set({
                cornerSize: 14,
                cornerColor: 'blue',
                cornerStyle: 'circle',
                transparentCorners: false,
              });
              editor.canvas.add(img);
              // editor.canvas.sendToBack(img);
              (img as any).id = 'customImage';
              img.on('mousedown', () => {
                showDelete(img, editor);
              });
              editor.canvas.renderAll();
            });
          };
          reader.readAsDataURL(blob);
        })
        .catch(error => {
          console.error('Error fetching image:', error);
        });
    }
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    setSheetOpen(false);
    if (files && files.length > 0 /* && printableArea */ && editor) {
      Array.from(files).forEach(file => {
        const reader = new FileReader();
        reader.onload = (event) => {
          fabric.Image.fromURL(event.target?.result as string, (oImg) => {
            const area = printableAreas.length > 0 && printableAreas[0]?.getBoundingRect();

            oImg.scaleToWidth(100);
            oImg.scaleToHeight(100);
            oImg.set({
              left: area?.left + 5 || 0,
              top: area?.top + 5 || 0,
            });
            oImg.setControlsVisibility({
              tl: true,
              tr: true,
              bl: true,
              br: true,
              ml: false, // Middle left (horizontal scaling)
              mr: false, // Middle right (horizontal scaling)
              mt: false, // Middle top (vertical scaling)
              mb: false,
            });
            oImg.set({
              cornerSize: 14,
              cornerColor: 'blue',
              cornerStyle: 'circle',
              transparentCorners: false,
            });
            editor.canvas.add(oImg);
            //editor.canvas.sendToBack(oImg);
            (oImg as any).id = 'customImage';
            oImg.on('mousedown', () => {
              showDelete(oImg, editor);
            });
            editor.canvas.renderAll()
          });
        };
        reader.readAsDataURL(file);
      });
    }
  };

  useEffect(() => { getLogoDesigns({}) }, [])

  return <Dialog open={open} onOpenChange={() => {
    if (!open) {
      setOpen(true);
    } else {
      handleClose();
    }
  }}>
    {/* <SheetTrigger asChild >
        <Button className='headermenu w-[150px]' size="sm">Select From Gallery</Button>
      </SheetTrigger> */}
    <DialogContent className="overflow-y-auto max-h-screen sm:w-[450px] " >
      <div>
        <Label htmlFor="local-image" className='headermenu p-2 rounded-lg text-white'>Upload Your Image</Label>
        <Input id="local-image" type="file" onChange={handleImageChange} className='hidden' />
      </div>
      {logos?.result?.length > 0 && <p className="mb-3 mt-4 font-semibold text-sm">Select image from Down:</p>}
      <div className='flex gap-3 flex-wrap my-3'>
        {logos?.result?.length > 0 && logos?.result?.map((logo: any) => <Card key={logo.id} className="w-[80px]" onClick={() => { setOpen(false); handleImageUpload(`${IMGURL}${logo.designUrl}`) }}>
          <img src={`${IMGURL}${logo.designUrl}`} alt="template" className="w-full h-[80px]" />
        </Card>)}
      </div>
    </DialogContent>
  </Dialog>
}

// export const SelectImageMug = ({ editor, setModalOpen, groupSelect, fixedTopLayerImg }: any) => {
//   const uniqueId = uuidv4();
//   const [getLogoDesigns, { data: logos }] = useGetLogodesignsMutation()
//   const [open, setOpen] = useState(true)
//   const handleClose = () => {
//     setOpen(false)
//     setModalOpen(false)
//   }
//   const handleImageUpload = (url: string) => {
//     setModalOpen(false);
//     if (!editor || !editor.canvas || !groupSelect) return;

//     // Check if url is a valid URL by checking if it starts with 'http' or 'https'
//     if (url.startsWith('http://') || url.startsWith('https://')) {
//       fetch(url, { mode: 'cors' })
//         .then(response => response.blob())
//         .then(blob => {
//           const reader = new FileReader();
//           reader.onload = () => {
//             fabric.Image.fromURL(reader.result as string, (img: fabric.Image) => {
//               img.set({
//                 crossOrigin: 'anonymous',
//                 angle:groupSelect?.angle||0,
//                 left: groupSelect.left,
//                 top: groupSelect.top,
//                 scaleX: (groupSelect.width! * (groupSelect.scaleX || 1)) / img.width!,
//                 scaleY: (groupSelect.height! * (groupSelect.scaleY || 1)) / img.height!,
//                 selectable: true,
//                 hasControls: true,
//                 hasBorders: true,
//               });
//               img.setControlsVisibility({
//                 tl: true,
//                 tr: true,
//                 bl: true,
//                 br: true,
//                 ml: false, // Middle left (horizontal scaling)
//                 mr: false, // Middle right (horizontal scaling)
//                 mt: false, // Middle top (vertical scaling)
//                 mb: false,
//               });
//               img.set({
//                 cornerSize: 14,
//                 cornerColor: 'blue',
//                 cornerStyle: 'circle',
//                 transparentCorners: false,
//               });
//               (img as any).id = 'groupSelectImage';
//               //editor.canvas.remove(groupSelect);
//               groupSelect.set({ visible: false })
//               editor.canvas.add(img);
//               if (fixedTopLayerImg) editor.canvas.sendToBack(img);
//               img.on('mousedown', () => {
//                 showDeleteIcon(img, editor, groupSelect);
//               });
//               (img as any).uuid = uniqueId;
//               (groupSelect as any).uuid = uniqueId;
//               editor.canvas.setActiveObject(img);
//               editor.canvas.renderAll();
//             }, { crossOrigin: 'anonymous' });
//           };
//           reader.readAsDataURL(blob);
//         })
//         .catch(error => {
//           console.error('Error fetching image:', error);
//         });
//     }
//   };

//   const handleLocalImageChange = (event: ChangeEvent<HTMLInputElement>) => {
//     const files = event.target.files;
//     setModalOpen(false);
//     if (files && editor && editor.canvas && groupSelect) {
//       const reader = new FileReader();
//       reader.onload = () => {
//         fabric.Image.fromURL(reader.result as string, (img) => {
//           if (img.width && img.height) {
//             const widthFix = (groupSelect.scaleX && groupSelect.width) ? groupSelect.width * groupSelect.scaleX : groupSelect.width!;
//             const heightFix = (groupSelect.scaleY && groupSelect.height) ? groupSelect.height * groupSelect.scaleY : groupSelect.height!;

//             img.set({
//               left: groupSelect.left,
//               angle:groupSelect?.angle||0,
//               top: groupSelect.top,
//               scaleX: widthFix / img.width,
//               scaleY: heightFix / img.height,
//               selectable: true,
//               hasControls: true,
//               hasBorders: true,
//             });
//             img.setControlsVisibility({
//               tl: true,
//               tr: true,
//               bl: true,
//               br: true,
//               ml: false, // Middle left (horizontal scaling)
//               mr: false, // Middle right (horizontal scaling)
//               mt: false, // Middle top (vertical scaling)
//               mb: false,
//             });
//             img.set({
//               cornerSize: 14,
//               cornerColor: 'blue',
//               cornerStyle: 'circle',
//               transparentCorners: false,
//             });
//             (img as any).id = 'groupSelectImage';
//             //editor.canvas.remove(groupSelect);
//             groupSelect.set({ visible: false })
//             editor.canvas.add(img);
//             if (fixedTopLayerImg) editor.canvas.sendToBack(img);
//             img.on('mousedown', () => {
//               showDeleteIcon(img, editor, groupSelect);
//             });
//             (img as any).uuid = uniqueId;
//             (groupSelect as any).uuid = uniqueId;
//             editor.canvas.setActiveObject(img);
//             editor.canvas.renderAll();
//           } else {
//             console.error("Image dimensions are not defined.");
//           }
//         });
//       };
//       reader.readAsDataURL(files[0]);
//     }
//   };

//   useEffect(() => { getLogoDesigns({}) }, [])

//   return <Sheet open={open} onOpenChange={() => {
//     if (!open) {
//       setOpen(true);
//     } else {
//       handleClose();
//     }
//   }}>
//     {/* <SheetTrigger asChild >
//         <Button className='headermenu w-[150px]' size="sm">Select From Gallery</Button>
//       </SheetTrigger> */}
//     <SheetContent side="left" className="overflow-y-scroll max-h-screen w-[350px] " >
//       <div>
//         <Label htmlFor="local-image" className='headermenu p-2 rounded-lg text-white'>Upload Your Image</Label>
//         <Input id="local-image" type="file" onChange={handleLocalImageChange} className='hidden' />
//       </div>
//       {logos?.result?.length > 0 && <p className="mb-3 mt-4 font-semibold text-sm">Select image from Down:</p>}
//       <div className='flex gap-3 flex-wrap my-3'>
//         {logos?.result?.length > 0 && logos?.result?.map((logo: any) => <Card key={logo.id} className="w-[80px]" onClick={() => { setOpen(false); handleImageUpload(`${IMGURL}${logo.designUrl}`) }}>
//           <img src={`${IMGURL}${logo.designUrl}`} alt="template" className="w-full h-[80px]" />
//         </Card>)}
//       </div>
//     </SheetContent>
//   </Sheet>
// }
export const SelectImageMug = ({ editor, setModalOpen, groupSelect, fixedTopLayerImg }: any) => {
  const uniqueId = uuidv4();
  const [getLogoDesigns, { data: logos }] = useGetLogodesignsMutation()
  const [open, setOpen] = useState(true)
  const handleClose = () => {
    setOpen(false)
    setModalOpen(false)
  }
  const handleImageUpload = (url: string) => {
    setModalOpen(false);
    if (!editor || !editor.canvas || !groupSelect) return;

    // Check if url is a valid URL by checking if it starts with 'http' or 'https'
    if (url.startsWith('http://') || url.startsWith('https://')) {
      fetch(url, { mode: 'cors' })
        .then(response => response.blob())
        .then(blob => {
          const reader = new FileReader();
          reader.onload = () => {
            fabric.Image.fromURL(reader.result as string, (img: fabric.Image) => {
              let scaleX = (groupSelect.width! * (groupSelect.scaleX || 1)) / img.width!;
              let scaleY = (groupSelect.height! * (groupSelect.scaleY || 1)) / img.height!;

              if (scaleX < scaleY) {
                scaleY = scaleX;
              } else {
                scaleX = scaleY;
              }
              img.set({
                crossOrigin: 'anonymous',
                angle: groupSelect?.angle || 0,
                left: groupSelect.left,
                top: groupSelect.top,
                scaleX,
                scaleY,
                // scaleX: (groupSelect.width! * (groupSelect.scaleX || 1)) / img.width!,
                // scaleY: (groupSelect.height! * (groupSelect.scaleY || 1)) / img.height!,
                selectable: true,
                hasControls: true,
                hasBorders: true,
              });
              img.setControlsVisibility({
                tl: true,
                tr: true,
                bl: true,
                br: true,
                ml: false, // Middle left (horizontal scaling)
                mr: false, // Middle right (horizontal scaling)
                mt: false, // Middle top (vertical scaling)
                mb: false,
              });
              img.set({
                cornerSize: 14,
                cornerColor: 'blue',
                cornerStyle: 'circle',
                transparentCorners: false,
              });
              (img as any).id = 'groupSelectImage';
              //editor.canvas.remove(groupSelect);
              groupSelect.set({ visible: false })
              editor.canvas.add(img);
              if (fixedTopLayerImg) editor.canvas.sendToBack(img);
              img.on('mousedown', () => {
                showDeleteIcon(img, editor, groupSelect);
              });
              (img as any).uuid = uniqueId;
              (groupSelect as any).uuid = uniqueId;
              editor.canvas.setActiveObject(img);
              editor.canvas.renderAll();
            }, { crossOrigin: 'anonymous' });
          };
          reader.readAsDataURL(blob);
        })
        .catch(error => {
          console.error('Error fetching image:', error);
        });
    }
  };

  const handleLocalImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    setModalOpen(false);
    if (files && editor && editor.canvas && groupSelect) {
      const reader = new FileReader();
      reader.onload = () => {
        fabric.Image.fromURL(reader.result as string, (img) => {
          if (img.width && img.height) {
            const widthFix = (groupSelect.scaleX && groupSelect.width) ? groupSelect.width * groupSelect.scaleX : groupSelect.width!;
            const heightFix = (groupSelect.scaleY && groupSelect.height) ? groupSelect.height * groupSelect.scaleY : groupSelect.height!;
            let scaleX = widthFix / img.width;
            let scaleY = heightFix / img.height;

            if (scaleX < scaleY) {
              scaleY = scaleX;
            } else {
              scaleX = scaleY;
            }
            img.set({
              left: groupSelect.left,
              angle: groupSelect?.angle || 0,
              top: groupSelect.top,
              scaleX,
              scaleY,
              // scaleX: widthFix / img.width,
              // scaleY: heightFix / img.height,
              selectable: true,
              hasControls: true,
              hasBorders: true,
            });
            img.setControlsVisibility({
              tl: true,
              tr: true,
              bl: true,
              br: true,
              ml: false, // Middle left (horizontal scaling)
              mr: false, // Middle right (horizontal scaling)
              mt: false, // Middle top (vertical scaling)
              mb: false,
            });
            img.set({
              cornerSize: 14,
              cornerColor: 'blue',
              cornerStyle: 'circle',
              transparentCorners: false,
            });
            (img as any).id = 'groupSelectImage';
            //editor.canvas.remove(groupSelect);
            groupSelect.set({ visible: false })
            editor.canvas.add(img);
            if (fixedTopLayerImg) editor.canvas.sendToBack(img);
            img.on('mousedown', () => {
              showDeleteIcon(img, editor, groupSelect);
            });
            (img as any).uuid = uniqueId;
            (groupSelect as any).uuid = uniqueId;
            editor.canvas.setActiveObject(img);
            editor.canvas.renderAll();
          } else {
            console.error("Image dimensions are not defined.");
          }
        });
      };
      reader.readAsDataURL(files[0]);
    }
  };

  useEffect(() => { getLogoDesigns({}) }, [])

  return <Dialog open={open} onOpenChange={() => {
    if (!open) {
      setOpen(true);
    } else {
      handleClose();
    }
  }}>
    {/* <SheetTrigger asChild >
        <Button className='headermenu w-[150px]' size="sm">Select From Gallery</Button>
      </SheetTrigger> */}
    <DialogContent className="overflow-y-auto max-h-screen sm:w-[450px] " >
      <div>
        <Label htmlFor="local-image" className='headermenu p-2 rounded-lg text-white'>Upload Your Image</Label>
        <Input id="local-image" type="file" onChange={handleLocalImageChange} className='hidden' />
      </div>
      {logos?.result?.length > 0 && <p className="mb-3 mt-4 font-semibold text-sm">Select image from Down:</p>}
      <div className='flex gap-3 flex-wrap my-3'>
        {logos?.result?.length > 0 && logos?.result?.map((logo: any) => <Card key={logo.id} className="w-[80px]" onClick={() => { setOpen(false); handleImageUpload(`${IMGURL}${logo.designUrl}`) }}>
          <img src={`${IMGURL}${logo.designUrl}`} alt="template" className="w-full h-[80px]" />
        </Card>)}
      </div>
    </DialogContent>
  </Dialog>
}
// export const SelectImagePrintableMug = ({ editor, setSheetOpen, printableAreas }: any) => {

//   const [getLogoDesigns, { data: logos }] = useGetLogodesignsMutation()
//   const [open, setOpen] = useState(true)
//   const handleClose = () => {
//     setOpen(false)
//     setSheetOpen(false)
//   }

//   const handleImageUpload = (url: string) => {
//     setSheetOpen(false);
//     if (!editor || !editor.canvas) return;

//     // Check if url is a valid URL by checking if it starts with 'http' or 'https'
//     if (url.startsWith('http://') || url.startsWith('https://')) {
//       fetch(url, { mode: 'cors' })
//         .then(response => response.blob())
//         .then(blob => {
//           const reader = new FileReader();
//           reader.onload = () => {
//             fabric.Image.fromURL(reader.result as string, (img: fabric.Image) => {
//               const area = printableAreas.length > 0 && printableAreas[0].getBoundingRect();
//               img.scaleToWidth(100);
//               img.scaleToHeight(100);
//               img.set({
//                 left: area?.left + 5 || 0,
//                 top: area?.top + 5 || 0,
//               });
//               img.setControlsVisibility({
//                 tl: true,
//                 tr: true,
//                 bl: true,
//                 br: true,
//                 ml: false, // Middle left (horizontal scaling)
//                 mr: false, // Middle right (horizontal scaling)
//                 mt: false, // Middle top (vertical scaling)
//                 mb: false,
//               });
//               img.set({
//                 cornerSize: 14,
//                 cornerColor: 'blue',
//                 cornerStyle: 'circle',
//                 transparentCorners: false,
//               });
//               editor.canvas.add(img);
//               // editor.canvas.sendToBack(img);
//               (img as any).id = 'customImage';
//               img.on('mousedown', () => {
//                 showDelete(img, editor);
//               });
//               editor.canvas.renderAll();
//             });
//           };
//           reader.readAsDataURL(blob);
//         })
//         .catch(error => {
//           console.error('Error fetching image:', error);
//         });
//     }
//   };

//   const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
//     const files = e.target.files;
//     setSheetOpen(false);
//     if (files && files.length > 0 /* && printableArea  */ && editor) {
//       Array.from(files).forEach(file => {
//         const reader = new FileReader();
//         reader.onload = (event) => {
//           fabric.Image.fromURL(event.target?.result as string, (oImg) => {
//             const area = printableAreas.length > 0 && printableAreas[0]?.getBoundingRect();
//             oImg.scaleToWidth(100);
//             oImg.scaleToHeight(100);
//             oImg.set({
//               left: area?.left + 5 || 0,
//               top: area?.top + 5 || 0,
//             });
//             oImg.setControlsVisibility({
//               tl: true,
//               tr: true,
//               bl: true,
//               br: true,
//               ml: false, // Middle left (horizontal scaling)
//               mr: false, // Middle right (horizontal scaling)
//               mt: false, // Middle top (vertical scaling)
//               mb: false,
//             });
//             oImg.set({
//               cornerSize: 14,
//               cornerColor: 'blue',
//               cornerStyle: 'circle',
//               transparentCorners: false,
//             });
//             editor.canvas.add(oImg);
//             // editor.canvas.sendToBack(oImg);
//             (oImg as any).id = 'customImage';
//             oImg.on('mousedown', () => {
//               showDelete(oImg, editor);
//             });
//             editor.canvas.renderAll();
//           });
//         };
//         reader.readAsDataURL(file);
//       });
//     }
//   };

//   useEffect(() => { getLogoDesigns({}) }, [getLogoDesigns])

//   return <Sheet open={open} onOpenChange={() => {
//     if (!open) {
//       setOpen(true);
//     } else {
//       handleClose();
//     }
//   }}>
//     {/* <SheetTrigger asChild >
//         <Button className='headermenu w-[150px]' size="sm">Select From Gallery</Button>
//       </SheetTrigger> */}
//     <SheetContent side="left" className="overflow-y-scroll max-h-screen w-[350px] " >
//       <div>
//         <Label htmlFor="local-image" className='headermenu p-2 rounded-lg text-white'>Upload Your Image</Label>
//         <Input id="local-image" type="file" onChange={handleImageChange} className='hidden' />
//       </div>
//       {logos?.result?.length > 0 && <p className="mb-3 mt-4 font-semibold text-sm">Select image from Down:</p>}
//       <div className='flex gap-3 flex-wrap my-3'>
//         {logos?.result?.length > 0 && logos?.result?.map((logo: any) => <Card key={logo.id} className="w-[80px]" onClick={() => { setOpen(false); handleImageUpload(`${IMGURL}${logo.designUrl}`) }}>
//           <img src={`${IMGURL}${logo.designUrl}`} alt="template" className="w-full h-[80px]" />
//         </Card>)}
//       </div>
//     </SheetContent>
//   </Sheet>
// }
export const SelectImagePrintableMug = ({ editor, setSheetOpen, printableAreas }: any) => {

  const [getLogoDesigns, { data: logos }] = useGetLogodesignsMutation()
  const [open, setOpen] = useState(true)
  const handleClose = () => {
    setOpen(false)
    setSheetOpen(false)
  }

  const handleImageUpload = (url: string) => {
    setSheetOpen(false);
    if (!editor || !editor.canvas) return;

    // Check if url is a valid URL by checking if it starts with 'http' or 'https'
    if (url.startsWith('http://') || url.startsWith('https://')) {
      fetch(url, { mode: 'cors' })
        .then(response => response.blob())
        .then(blob => {
          const reader = new FileReader();
          reader.onload = () => {
            fabric.Image.fromURL(reader.result as string, (img: fabric.Image) => {
              const area = printableAreas.length > 0 && printableAreas[0].getBoundingRect();
              img.scaleToWidth(100);
              img.scaleToHeight(100);
              img.set({
                left: area?.left + 5 || 0,
                top: area?.top + 5 || 0,
              });
              img.setControlsVisibility({
                tl: true,
                tr: true,
                bl: true,
                br: true,
                ml: false, // Middle left (horizontal scaling)
                mr: false, // Middle right (horizontal scaling)
                mt: false, // Middle top (vertical scaling)
                mb: false,
              });
              img.set({
                cornerSize: 14,
                cornerColor: 'blue',
                cornerStyle: 'circle',
                transparentCorners: false,
              });
              editor.canvas.add(img);
              // editor.canvas.sendToBack(img);
              (img as any).id = 'customImage';
              img.on('mousedown', () => {
                showDelete(img, editor);
              });
              editor.canvas.renderAll();
            });
          };
          reader.readAsDataURL(blob);
        })
        .catch(error => {
          console.error('Error fetching image:', error);
        });
    }
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    setSheetOpen(false);
    if (files && files.length > 0 /* && printableArea  */ && editor) {
      Array.from(files).forEach(file => {
        const reader = new FileReader();
        reader.onload = (event) => {
          fabric.Image.fromURL(event.target?.result as string, (oImg) => {
            const area = printableAreas.length > 0 && printableAreas[0]?.getBoundingRect();
            oImg.scaleToWidth(100);
            oImg.scaleToHeight(100);
            oImg.set({
              left: area?.left + 5 || 0,
              top: area?.top + 5 || 0,
            });
            oImg.setControlsVisibility({
              tl: true,
              tr: true,
              bl: true,
              br: true,
              ml: false, // Middle left (horizontal scaling)
              mr: false, // Middle right (horizontal scaling)
              mt: false, // Middle top (vertical scaling)
              mb: false,
            });
            oImg.set({
              cornerSize: 14,
              cornerColor: 'blue',
              cornerStyle: 'circle',
              transparentCorners: false,
            });
            editor.canvas.add(oImg);
            // editor.canvas.sendToBack(oImg);
            (oImg as any).id = 'customImage';
            oImg.on('mousedown', () => {
              showDelete(oImg, editor);
            });
            editor.canvas.renderAll();
          });
        };
        reader.readAsDataURL(file);
      });
    }
  };

  useEffect(() => { getLogoDesigns({}) }, [getLogoDesigns])

  return <Dialog open={open} onOpenChange={() => {
    if (!open) {
      setOpen(true);
    } else {
      handleClose();
    }
  }}>
    <DialogContent className="overflow-y-auto max-h-screen sm:w-[450px] " >
      <div>
        <Label htmlFor="local-image" className='headermenu p-2 rounded-lg text-white'>Upload Your Image</Label>
        <Input id="local-image" type="file" onChange={handleImageChange} className='hidden' />
      </div>
      {logos?.result?.length > 0 && <p className="mb-3 mt-4 font-semibold text-sm">Select image from Down:</p>}
      <div className='flex gap-3 flex-wrap my-3'>
        {logos?.result?.length > 0 && logos?.result?.map((logo: any) => <Card key={logo.id} className="w-[80px]" onClick={() => { setOpen(false); handleImageUpload(`${IMGURL}${logo.designUrl}`) }}>
          <img src={`${IMGURL}${logo.designUrl}`} alt="template" className="w-full h-[80px]" />
        </Card>)}
      </div>
    </DialogContent>
  </Dialog>
}
export const showDeleteIcon = (img: fabric.Image | any, editor: any, groupSelect: any) => {
  // Create or update the delete icon container
  const deleteIconContainer = document.createElement('div');
  deleteIconContainer.id = 'delete-icon-root';
  deleteIconContainer.style.position = 'absolute';
  deleteIconContainer.style.zIndex = '9999'; // Ensure the delete icon is on top
  document.body.appendChild(deleteIconContainer);

  const DeleteIcon = () => {
    return (
      <div
        id="delete-icon"
        style={{
          padding: '2px',
          cursor: 'pointer',
        }}
        onClick={() => {
          // Remove the image from the canvas
          if (editor.canvas && img) {
            editor.canvas.remove(img);
            groupSelect.set({ visible: true });
            editor.canvas.renderAll();
          }
          deleteIconContainer.remove(); // Remove the delete icon
        }}
      >
        <MdDelete color="red" size={24} />
      </div>
    );
  };


  // Render the delete icon using React
  const deleteIconRoot = ReactDOM.createRoot(deleteIconContainer);
  deleteIconRoot.render(<DeleteIcon />);

  // const updateIconPosition = () => {
  //   if (editor.canvas && img) {
  //     const canvasRect = editor.canvas.wrapperEl.getBoundingClientRect();
  //     const imgDime = img.getBoundingRect();
  //     const canvasTop = canvasRect.top + window.scrollY;
  //     const canvasLeft = canvasRect.left + window.scrollX;
  //     deleteIconContainer.style.top = `${canvasTop + (img.top! + imgDime.height + 5) * editor.canvas.getZoom()}px`;
  //     deleteIconContainer.style.left = `${canvasLeft + (img.left! + imgDime.width / 2) * editor.canvas.getZoom()}px`;
  //   }
  // };
  const updateIconPosition = () => {
    if (editor.canvas && img) {
      const canvasRect = editor.canvas.wrapperEl.getBoundingClientRect();
      const zoom = editor.canvas.getZoom();
      const imgBoundingBox = img.getBoundingRect();
      const canvasTop = canvasRect.top + window.scrollY;
      const canvasLeft = canvasRect.left + window.scrollX;
      deleteIconContainer.style.top = `${canvasTop + (imgBoundingBox.top + imgBoundingBox.height + 5) * zoom}px`;
      deleteIconContainer.style.left = `${canvasLeft + (imgBoundingBox.left + imgBoundingBox.width / 2) * zoom}px`;
    }
  };


  updateIconPosition();

  // Cleanup the delete icon when the image is deselected or removed
  const cleanupDeleteIcon = () => {
    if (deleteIconContainer) {
      deleteIconContainer.remove();
    }
  };

  img.on('deselected', cleanupDeleteIcon);

  // Update the delete icon position on movement or scaling
  editor.canvas.on('object:moving', updateIconPosition);
  editor.canvas.on('object:scaling', updateIconPosition);
  editor.canvas.on('after:render', updateIconPosition);

  // Clear the canvas when navigating to other pages or reloading
  const clearCanvasOnNavigation = () => {
    if (editor.canvas) {
      editor.canvas.clear();  // Clear all objects from the canvas
      editor.canvas.renderAll(); // Re-render the empty canvas
    }
    cleanupDeleteIcon();  // Remove the delete icon on navigation
    window.removeEventListener('beforeunload', clearCanvasOnNavigation); // Clean up event listeners
    window.removeEventListener('unload', clearCanvasOnNavigation);
  };

  window.addEventListener('beforeunload', clearCanvasOnNavigation); // Listen for navigation or reload events
  window.addEventListener('unload', clearCanvasOnNavigation); // Listen for unload events

  const checkCanvasAvailability = () => {
    if (!editor.canvas || !editor.canvas.wrapperEl || !img) {
      cleanupDeleteIcon();
    }
  };

  // Cleanup the delete icon if the canvas is destroyed or navigated away
  const observeCanvasChanges = new MutationObserver(() => {
    checkCanvasAvailability();
  });

  observeCanvasChanges.observe(editor.canvas.wrapperEl, {
    childList: true,
    subtree: true,
  });

  // Also, remove the delete icon when the selection is cleared
  editor.canvas.on('selection:cleared', cleanupDeleteIcon);
};
export const showDelete = (img: fabric.Image | any, editor: any) => {
  // Create or update the delete icon container
  const deleteIconContainer = document.createElement('div');
  deleteIconContainer.id = 'delete-icon-root';
  deleteIconContainer.style.position = 'absolute';
  deleteIconContainer.style.zIndex = '9999'; // Ensure the delete icon is on top
  document.body.appendChild(deleteIconContainer);

  const DeleteIcon = () => {
    return (
      <div
        id="delete-icon"
        style={{
          padding: '2px',
          cursor: 'pointer',
        }}
        onClick={() => {
          // Remove the image from the canvas
          if (editor.canvas && img) {
            editor.canvas.remove(img);
            editor.canvas.renderAll();
          }
          deleteIconContainer.remove(); // Remove the delete icon
        }}
      >
        <MdDelete color="red" size={24} />
      </div>
    );
  };


  // Render the delete icon using React
  const deleteIconRoot = ReactDOM.createRoot(deleteIconContainer);
  deleteIconRoot.render(<DeleteIcon />);

  // const updateIconPosition = () => {
  //   if (editor.canvas && img) {
  //     const canvasRect = editor.canvas.wrapperEl.getBoundingClientRect();
  //     const imgDime = img.getBoundingRect();
  //     const canvasTop = canvasRect.top + window.scrollY;
  //     const canvasLeft = canvasRect.left + window.scrollX;
  //     deleteIconContainer.style.top = `${canvasTop + (img.top! + imgDime.height + 5) * editor.canvas.getZoom()}px`;
  //     deleteIconContainer.style.left = `${canvasLeft + (img.left! + imgDime.width / 2) * editor.canvas.getZoom()}px`;
  //   }
  // };
  const updateIconPosition = () => {
    if (editor.canvas && img) {
      const canvasRect = editor.canvas.wrapperEl.getBoundingClientRect();
      const zoom = editor.canvas.getZoom();
      const imgBoundingBox = img.getBoundingRect();
      const canvasTop = canvasRect.top + window.scrollY;
      const canvasLeft = canvasRect.left + window.scrollX;
      deleteIconContainer.style.top = `${canvasTop + (imgBoundingBox.top + imgBoundingBox.height + 5) * zoom}px`;
      deleteIconContainer.style.left = `${canvasLeft + (imgBoundingBox.left + imgBoundingBox.width / 2) * zoom}px`;
    }
  };
  updateIconPosition();

  // Cleanup the delete icon when the image is deselected or removed
  const cleanupDeleteIcon = () => {
    if (deleteIconContainer) {
      deleteIconContainer.remove();
    }
  };

  img.on('deselected', cleanupDeleteIcon);

  // Update the delete icon position on movement or scaling
  editor.canvas.on('object:moving', updateIconPosition);
  editor.canvas.on('object:scaling', updateIconPosition);
  editor.canvas.on('after:render', updateIconPosition);

  // Clear the canvas when navigating to other pages or reloading
  const clearCanvasOnNavigation = () => {
    if (editor.canvas) {
      editor.canvas.clear();  // Clear all objects from the canvas
      editor.canvas.renderAll(); // Re-render the empty canvas
    }
    cleanupDeleteIcon();  // Remove the delete icon on navigation
    window.removeEventListener('beforeunload', clearCanvasOnNavigation); // Clean up event listeners
    window.removeEventListener('unload', clearCanvasOnNavigation);
  };

  window.addEventListener('beforeunload', clearCanvasOnNavigation); // Listen for navigation or reload events
  window.addEventListener('unload', clearCanvasOnNavigation); // Listen for unload events

  const checkCanvasAvailability = () => {
    if (!editor.canvas || !editor.canvas.wrapperEl || !img) {
      cleanupDeleteIcon();
    }
  };

  // Cleanup the delete icon if the canvas is destroyed or navigated away
  const observeCanvasChanges = new MutationObserver(() => {
    checkCanvasAvailability();
  });

  observeCanvasChanges.observe(editor.canvas.wrapperEl, {
    childList: true,
    subtree: true,
  });

  // Also, remove the delete icon when the selection is cleared
  editor.canvas.on('selection:cleared', cleanupDeleteIcon);
};

